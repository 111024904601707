<template>
  <div>
    <EditBrand
      @editDetail="editCategory"
      @cancelEdit="cancelEdit"
      @updateSlugAlreadyExists="updateSlugAlreadyExists"
      :brandId="brandId"
      :slugAlreadyExists="slugAlreadyExists"
    />

    <ProductList :brandId="brandId" />
  </div>
</template>

<script>
import EditBrand from "../../../components/WebStore/brands/edit";
import ProductList from "../../../components/WebStore/brands/productList";
import { mapActions } from 'vuex';

export default {
  name: 'Edit-Brand',
  components:{
    EditBrand,
    ProductList
  },
  data: () => ({
    brandId: '',
    slugAlreadyExists: false,
  }),
  methods:{
    ...mapActions("brand", [
      "updateBrandDetails"
    ]),
    ...mapActions("ecommerce",["algoliaSync"]),

    editCategory({ brandName, description, slug, isPopular, hasCustomPage, desktopImage, mobileImage }) {
      let self = this;
      this.updateBrandDetails({
        id: this.brandId,
        info: {
          brandName,
          description,
          slug,
          isPopular,
          hasCustomPage,
          desktopImage,
          mobileImage
        }
      }).then(res => {
        self.algoliaSync()
        self.$vs.notify({
          title: "Brand Edit",
          text: "Brand edited successfully",
          color: "success",
        });
        this.slugAlreadyExists = false;
        self.$vs.loading.close();
        this.$router.push({ name: 'BrandManagement'})
      }).catch((e)=>{
        self.$vs.notify({
          title: "Brand Edit",
          text: `${e.data.message}`,
          color: "danger",
        });
        this.slugAlreadyExists = true;
      });
    },
    cancelEdit() {
      this.$router.push({ name: "BrandManagement"});
    },
    updateSlugAlreadyExists() {
      this.slugAlreadyExists = false;
    },
  },
  async created() {
    this.brandId = this.$route.params.brandId;
  }
};
</script>
